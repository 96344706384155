<div>
  <mat-icon class='refresh-icon' fontIcon="refresh"></mat-icon>
  <span>
    Refresh automatically in
    <ng-template [ngTemplateOutlet]='timer'></ng-template>&nbsp;<ng-template [ngTemplateOutlet]='timeDescription'></ng-template>
  </span>
</div>

<ng-template #timer>
  <ng-container *ngIf='timeLeft <= 60; else minutes'>{{ timeLeft }}</ng-container>
  <ng-template #minutes>{{ Math.ceil(timeLeft / 60) }}</ng-template>
</ng-template>

<ng-template #timeDescription>
  <ng-container *ngIf='timeLeft <= 60; else minutes'>
    <ng-container *ngIf='timeLeft === 1; else seconds'>
      second
    </ng-container>
    <ng-template #seconds>seconds</ng-template>
  </ng-container>
  <ng-template #minutes>minutes</ng-template>
</ng-template>
