<mat-form-field class="full-width">
  <input matInput (keyup)="applyFilter($event)" placeholder="Filter by: call key, customer, service, location, servicing club, status, agent status">
</mat-form-field>

<div class="refresh-area">
  <button mat-button color="primary" class="refresh-button" (click)="requestCalls()"><mat-icon class='refresh-icon' fontIcon="refresh"></mat-icon> Refresh dashboard</button>
  <app-refresh-feedback [onRefresh]='requestCalls' [refreshInSeconds]='60'></app-refresh-feedback>
</div>

<ng-container *ngIf='loadingCalls else listCalls'>
  <div style='width: 100%; padding-left: 50%;'>
    <mat-spinner diameter='50'></mat-spinner>
    <div style='margin-top: 20px; margin-left: -38px;'>Loading active calls...</div>
  </div>
</ng-container>

<ng-template #listCalls>
  <table mat-table [dataSource]='dataSource' matSort>
    <ng-container matColumnDef='callKey'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CALL KEY</th>
      <td mat-cell *matCellDef='let call'>{{ call.callKey }}</td>
    </ng-container>

    <ng-container matColumnDef='createdAt'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CREATED AT</th>
      <td mat-cell *matCellDef='let call'>{{ call.createdAt | timezoneDate : call.timezoneId }}</td>
    </ng-container>

    <ng-container matColumnDef='customer'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CUSTOMER</th>
      <td mat-cell *matCellDef='let call'>{{ call.customer }}</td>
    </ng-container>

    <ng-container matColumnDef='service'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SERVICE</th>
      <td mat-cell *matCellDef='let call'>{{ call.service }}</td>
    </ng-container>

    <ng-container matColumnDef='pickupLocation'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>PICKUP LOCATION</th>
      <td mat-cell *matCellDef='let call'>{{ call.pickupLocation }}</td>
    </ng-container>

    <ng-container matColumnDef='dropOffLocation'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DROP OFF LOCATION</th>
      <td mat-cell *matCellDef='let call'>{{ call.dropOffLocation }}</td>
    </ng-container>

    <ng-container matColumnDef='servicingClub'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>SERVICING CLUB</th>
      <td mat-cell *matCellDef='let call'>{{ call.servicingClub }}</td>
    </ng-container>

    <ng-container matColumnDef='status'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
      <td mat-cell *matCellDef='let call'>{{ call.status }}</td>
    </ng-container>

    <ng-container matColumnDef='agentStatus'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AGENT STATUS</th>
      <td mat-cell *matCellDef='let call'>{{ call.agentStatus }}</td>
    </ng-container>

    <ng-container matColumnDef='eta'>
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ETA</th>
      <td mat-cell *matCellDef='let call'>{{ call.eta }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
    <tr mat-row *matRowDef='let row; columns: displayedColumns;' (click)='openDialog(row.callKey)' class='mat-row'></tr>
  </table>
</ng-template>

<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
