import { Pipe, PipeTransform } from '@angular/core'

const MAPPING: { [key: string]: string } = {
  'CR': 'Created',
  'HS': 'Spotted and On Hold',
  'RE': 'Received',
  'SP': 'Spotted',
  'BD': 'Bid',
  'AS': 'Assigned',
  'DC': 'Declined',
  'DI': 'Dispatched',
  'ER': 'En Route',
  'ET': 'ETA',
  'OL': 'On Location',
  'TW': 'Towing',
  'CA': 'Cancel Requested',
  'CP': 'Clear Pending',
  'CL': 'Cleared or Closed',
}

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  transform(value: string | undefined): unknown {
    return value ? (MAPPING[value] || value) : ''
  }

}
