import { Pipe, PipeTransform } from '@angular/core'
import Address from 'lib-automotive-call-cdm/src/models/cdm/shared/address'
import { TitleCasePipe } from '@angular/common'

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address | undefined): string {
    if (address?.full) {
      return address.full
    }
    if(address) {
      const titleCase = new TitleCasePipe()
      const streetNumber = address.streetNumber ? `${address.streetNumber} ` : ''
      const street = `${titleCase.transform(address.streetName || address.street)}`.replace(streetNumber, '')
      return `${streetNumber}${street}, ${address.city?.toUpperCase()}, ${address.state} ${address.postalCode || ''}`
    }
    return ''
  }

}
