import { Pipe, PipeTransform } from '@angular/core';
import ServiceTracking from 'lib-automotive-call-cdm/src/models/cdm/call/serviceTracking'

@Pipe({
  name: 'trackingLink'
})
export class TrackingLinkPipe implements PipeTransform {

  transform(serviceTrackingList: ServiceTracking[] | undefined): string {
    if (!serviceTrackingList?.length) {
      return ''
    }
    return [...serviceTrackingList].sort((serviceTrackingA, serviceTrackingB) =>
      serviceTrackingA.id > serviceTrackingB.id ? -1 : 1
    )[0].link
  }

}
