<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  <ng-container *ngIf='data.template else message'>
    <ng-container *ngTemplateOutlet="data.template"></ng-container>
  </ng-container>
  <ng-template #message>
    {{data.message}}
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <button mat-button mat-dialog-close>{{ data.cancelLabel || 'Cancel' }}</button>
  <button mat-button *ngIf='data.accept' [mat-dialog-close]="true" cdkFocusInitial (click)='accept()'>{{ data.confirmLabel || 'Confirm' }}</button>
</mat-dialog-actions>
