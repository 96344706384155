export const environment = {
  production: true,
  calls: {
    displayRange: 48
  },
  axiosConfig: {
    baseURL: 'https://api-uat.national.aaa.com/automotive/services/agent/bff',
    withCredentials: true,
    timeout: 10000,
    headers: {
      post: {
        'Content-Type': 'application/json'
      }
    }
  },
  okta: {
    issuer: 'https://clublogin.national.aaa.com/oauth2/default',
    clientId: '0oa6mkhwv79SRwPuV697',
    redirectUri: window.location.origin + '/login/callback'
  },
  drrWeb: {
    baseUrl: 'https://drrweb-uat.national.aaa.com',
    queryParams: {
      sti: '3600000',
      asti: '3000000',
      preprod: 'Y'
    }
  }
};
