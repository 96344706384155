import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { OktaAuthModule } from '@okta/okta-angular'
import OktaAuth from '@okta/okta-auth-js'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CreateCallComponent } from './create-call/create-call.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatToolbarModule } from '@angular/material/toolbar'
import { GoogleMapsService } from './services/google-maps.service'
import { FormsModule } from '@angular/forms'
import { MatTableModule } from '@angular/material/table'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatOptionModule } from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { CallDetailsDialogComponent } from './call-details-dialog/call-details-dialog.component'
import { MatDialogModule } from '@angular/material/dialog'
import { environment } from '../environments/environment'
import { StoreModule } from '@ngrx/store'
import { callsReducer } from './reducer/calls.reducer'
import { EffectsModule } from '@ngrx/effects'
import { CallsEffects } from './effects/calls.effects'
import { ServiceDescriptionPipe } from './pipes/service-description.pipe'
import { AddressPipe } from './pipes/address.pipe'
import { StatusPipe } from './pipes/status.pipe'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { DATE_PIPE_DEFAULT_OPTIONS, TitleCasePipe } from '@angular/common'
import { RefreshFeedbackComponent } from '@shared/refresh-feedback/refresh-feedback.component';
import { TrackingLinkPipe } from './pipes/tracking-link.pipe'
import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { TimezoneDatePipe } from './pipes/timezone-date.pipe'
import { DATE_FORMAT } from './utils/constants';
import { EtaPipe } from './pipes/eta.pipe'

const oktaAuth = new OktaAuth({
  ...environment.okta,
})

@NgModule({
  declarations: [
    ServiceDescriptionPipe,
    AddressPipe,
    StatusPipe,
    AppComponent,
    CreateCallComponent,
    DashboardComponent,
    CallDetailsDialogComponent,
    RefreshFeedbackComponent,
    TrackingLinkPipe,
    GenericDialogComponent,
    TimezoneDatePipe,
    EtaPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    MatCardModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    StoreModule.forRoot({
      calls: callsReducer
    }),
    EffectsModule.forRoot(CallsEffects),
  ],
  providers: [
    GoogleMapsService,
    AddressPipe,
    ServiceDescriptionPipe,
    TitleCasePipe,
    StatusPipe,
    EtaPipe,
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: DATE_FORMAT } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
