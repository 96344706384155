import { createAction, props } from '@ngrx/store'
import { AgentCall, GetCallsRequest } from '../model/models'
import { DateTime } from 'luxon'

export const loadAllCalls = createAction(
  '[Call] Load All Calls',
  props<{ startDateTime: DateTime }>()
)

export const loadCalls = createAction(
  '[Call] Load Calls',
  props<{
    payload: GetCallsRequest
  }>()
)

export const resetCalls = createAction(
  '[Call] Reset Calls'
)

export const addCalls = createAction(
  '[Call] Add Calls',
  props<{ calls: AgentCall[] }>()
)

export const loadCallDetails = createAction(
  '[Call] Load Call Details',
  props<{ callKey: string }>()
)

export const setCall = createAction(
  '[Call] Set Call',
  props<{ call: AgentCall }>()
)

// export const addComment = createAction(
//   '[Call] Add Comment',
//   props<{ text: string }>()
// )
//
// export const updateAgentStatus = createAction(
//   '[Call] Update Agent Status',
//   props<{
//     callKey: string,
//     status: StatusType
//   }>()
// )
