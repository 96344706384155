import { createReducer, on } from '@ngrx/store'
import { resetCalls, setCall, addCalls } from '../actions/calls.actions'
import { AgentCall } from '../model/models'

export interface CallsState {
  calls: AgentCall[]
  call?: AgentCall
}

export const initialState: CallsState = {
  calls: []
}

export const callsReducer = createReducer(
  initialState,
  on(resetCalls, (state): CallsState => ({ ...state, calls: initialState.calls })),
  on(addCalls, (state, { calls }): CallsState => ({
    ...state,
    calls: [
      ...state.calls,
      ...calls
    ]
  })),
  on(setCall, (state, { call }): CallsState => ({ ...state, call })),
)
