import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, switchMap } from 'rxjs'
import { map } from 'rxjs/operators'
import { ApiService } from '../services/api.service'
import { addCalls, loadAllCalls, loadCallDetails, loadCalls, resetCalls, setCall } from '../actions/calls.actions'

@Injectable()
export class CallsEffects {

  appIds: string[] = ['BLUESKYDRR', 'BLUESKYNM']

  loadAllCalls$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loadAllCalls),
        switchMap(({ startDateTime }) => [
          resetCalls(),
          ...this.appIds.map(appId => loadCalls({
            payload: {
              appId,
              startDateTime,
            }
          }))
        ])
      )
    }
  )

  loadCalls$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loadCalls),
        mergeMap((action) => this.apiService.getCalls(action.payload)),
        map(response => addCalls({ calls: response.data.calls }))
      )
    }
  )

  loadCallDetails$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loadCallDetails),
        switchMap(({ callKey }) => this.apiService.getCallDetail(callKey)),
        map(response => setCall({ call: response.data }))
      )
    }
  )

  constructor(
    private actions$: Actions,
    private apiService: ApiService
  ) {
  }
}
