import { Component, Inject, TemplateRef } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export interface GenericDialog {
  message?: string
  template?: TemplateRef<any>
  accept?: () => void
  reject?: () => void
  title: string
  cancelLabel?: string
  confirmLabel?: string
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenericDialog
  ) {
  }

  accept = () => {
    if (this.data.accept) {
      this.data.accept()
    }
  }

}
