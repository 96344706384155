import { Component, Input, OnDestroy, OnInit } from '@angular/core'

const DEFAULT_REFRESH_INTERVAL = 3
const SECOND = 1000

@Component({
  selector: 'app-refresh-feedback',
  templateUrl: './refresh-feedback.component.html',
  styleUrls: ['./refresh-feedback.component.scss']
})
export class RefreshFeedbackComponent implements OnInit, OnDestroy {

  constructor() {
  }

  get refreshInSeconds(): number {
    return this._refreshInSeconds || DEFAULT_REFRESH_INTERVAL
  }
  @Input()
  set refreshInSeconds(value: number) {
    this._refreshInSeconds = value
    this.timeLeft = this.refreshInSeconds
  }

  private _refreshInSeconds?: number

  @Input() onRefresh: () => void = () => {
    console.log('Time to refresh...')
  }
  timeLeft: number = this.refreshInSeconds
  private interval: any

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.timeLeft--
      if(this.timeLeft === 0) {
        this.timeLeft = this.refreshInSeconds
        this.refreshTime()
      }
    }, SECOND)
  }

  refreshTime = () => this.onRefresh()

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  protected readonly Math = Math
}
