import { Pipe, PipeTransform } from '@angular/core';
import { TroubleCodeType } from 'lib-automotive-call-cdm/src/models/cdm/call/service/service'

const MAPPING: { [key: string]: string } = {
  'L101': 'Flat tire',
  'L102': 'Flat tire',
  'L103': 'Multiple Flat Tires',
  'L104': 'Flat tire',
  'L105': 'Bicycle Assist Service',
  'T180': 'Flat tire',
  'L190': 'Other Tire Problem',
  'L201': 'Engine Overheat',
  'T280': 'Engine Overheat',
  'L301': 'Car won\'t start / Battery issue',
  'L302': 'Battery Test or Replace',
  'L303': 'No Crank - Bat Svc (AAA Bat)',
  'L304': 'Member Requests Battery Service',
  'T380': 'Need a tow',
  'T381': 'Known Starter Problem',
  'T382': 'Known Alternator Problem',
  'L390': 'Other No Crank Problem',
  'L401': 'Car won\'t start',
  'L402': 'Out of fuel',
  'L403': 'Out of fuel',
  'L404': 'Electric Vehicle (EV) Out of Charge',
  'T480': 'Need a tow',
  'T481': 'Vehicle Mis-Fueled',
  'T483': 'Out of charge',
  'T484': 'Known Alternator Problem',
  'L490': 'Other Crank No Start Problem',
  'L501': 'Won\'t Go Into Gear',
  'L505': 'Parking Brake Won\'t Release',
  'L506': 'Hood/Door Won\'t Close/Latch',
  'L507': 'Undercar Component Dragging',
  'L508': 'Leaking Fluids',
  'L509': 'Windshield Damage',
  'L510': 'Car Alarm Issue',
  'T580': 'Transmission/Clutch Failure',
  'T581': 'Axle/Driveshaft/Suspension Failure',
  'T582': 'Brake System Failure',
  'L590': 'Other Runs Won\'t Move Problem',
  'T601': 'Engine Stalled While Driving (or Engine Runs Poorly)',
  'T680': 'Car won\'t start',
  'T690': 'Engine Stalled While Driving (or Other Runs Poorly Problem)',
  'L701': 'Locked out',
  'L702': 'Key Locked In Trunk w/Trunk Release',
  'L703': 'Key Broken In Door - Not Ignition Key',
  'L704': 'Frozen Door Lock',
  'L790': 'Other Lockout Problem',
  'L801': 'Locked out',
  'L802': 'Key Locked In Trunk-No Trunk Release',
  'L803': 'Lost/Damaged Vehicle Key',
  'L804': 'Lost/Damaged "Club" Key',
  'L805': 'Key Broken In Door - Ignition Key',
  'L806': 'Key Broken In Ignition Switch',
  'L807': 'Home Lockout',
  'L890': 'Locked out',
  'T905': 'Vehicle Fire',
  'T906': 'Accident',
  'T907': 'Convenience/Member Concern Tow',
  'T921': 'Insurance Accident Assist',
  'T922': 'Insurance Accident Assist - Second Tow',
  'T931': 'Stuck',
  'T932': 'Stuck',
  'T980': 'Light Service Redispatch As Tow',
  'T990': 'Other Required Tow',
  'T1': 'Flat tire',
  'T2': 'Flat tire',
  'T3': 'Battery',
  'T4': 'Car won\'t start',
  'T5': 'Out of fuel',
  'T6': 'Need a tow',
  'T7': 'Locked out',
  '7A': 'Locked out',
  '7B': 'Locked out',
  'T8': 'Stuck'

}

@Pipe({
  name: 'serviceDescription'
})
export class ServiceDescriptionPipe implements PipeTransform {

  transform(value: {
    code: string;
    description: string;
    troubleCodeType: TroubleCodeType;
  }[] | undefined): unknown {
    return value?.length ? (MAPPING[value[0].code] || value[0].code) : 'N/A'
  }

}
