import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { GoogleMapsService } from '../services/google-maps.service'
import { OktaAuthStateService } from '@okta/okta-angular'
import { ApiService } from '../services/api.service'
import { v4 as uuidv4 } from 'uuid'
import { StateManagedComponent } from '../utils/StateManagedComponent'
import { Subscription } from 'rxjs'
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-create-call',
  templateUrl: './create-call.component.html',
  styleUrls: ['./create-call.component.scss']
})
export class CreateCallComponent extends StateManagedComponent implements AfterViewInit {
  @ViewChild('mapContainer') mapContainer!: ElementRef
  latitude = 0
  longitude = 0
  user = {
    firstName: '',
    lastName: ''
  }
  rapAppLinkLoading = false

  constructor (
    private readonly googleMapsService: GoogleMapsService,
    private readonly _oktaStateService: OktaAuthStateService,
    private apiService: ApiService,
  ) {
    super()
  }

  ngAfterViewInit (): void {
    this.googleMapsService.initializeMap(
      this.mapContainer.nativeElement,
      this.latitude,
      this.longitude
    )
  }

  updateMap (): void {
    this.googleMapsService.updateMarker(this.latitude, this.longitude)
  }

  navigate (): void {
    window.open(`${environment.drrWeb.baseUrl}/auth/signin?appid=BLUESKYDRR&${this.buildDefaultQueryParams()}`, '_blank')
  }

  navigateRap(): void {
    this.rapAppLinkLoading = true
    this.apiService
      .getRapAppLink(uuidv4())
      .then((rapAppLink) => window.open(`${rapAppLink.data.data.link}&${this.buildDefaultQueryParams()}`, '_blank'))
      .catch((err) => {
        console.error(err)
        alert('Something went wrong.')
      })
      .finally(() => this.rapAppLinkLoading = false)
  }

  buildDefaultQueryParams = (): string => {
    const firstName = encodeURIComponent(this.user.firstName)
    const lastName = encodeURIComponent(this.user.lastName)
    const extraQueryParams = new URLSearchParams(environment.drrWeb.queryParams).toString()
    return `agent-first-name=${firstName}&agent-last-name=${lastName}&lat=${this.latitude}&lng=${this.longitude}&${extraQueryParams}`
  }

  subscribers(): Subscription[] {
    return [
      this._oktaStateService.authState$.subscribe((authState) => {
        this.user = {
          firstName: authState.idToken?.claims['firstName']?.toString() ?? '',
          lastName: authState.idToken?.claims['lastName']?.toString() ?? ''
        }
      })
    ]
  }
}
