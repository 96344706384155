import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  template: ''
})
export abstract class StateManagedComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription[] = []

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
  }

  ngOnInit(): void {
    this._subscriptions = this.subscribers()
  }

  abstract subscribers(): Subscription[];

}
