import { Injectable } from '@angular/core'

declare const google: any

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private map!: google.maps.Map
  private marker!: google.maps.Marker

  initializeMap (element: HTMLElement, lat: number, lng: number): void {
    const mapOptions: google.maps.MapOptions = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 12,
      scaleControl: true,
    }
    this.map = new google.maps.Map(element, mapOptions)
    this.marker = new google.maps.Marker({
      position: { lat, lng },
      map: this.map
    })
  }

  updateMarker (lat: number, lng: number): void {
    this.marker.setPosition({ lat, lng })
    this.map.panTo({ lat, lng })
  }
}
