import { Component, Inject } from '@angular/core'
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular'
import { OktaAuth } from '@okta/okta-auth-js'
import packageJson from '../../package.json'
import { ApiService } from './services/api.service'
import { StateManagedComponent } from './utils/StateManagedComponent'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends StateManagedComponent {
  title = 'ersagent-frontend'
  authenticated = false
  firstName = ''
  lastName = ''
  clientId = ''
  clientSecret = ''
  appVersion: string = packageJson.version;

  constructor (
    private readonly _oktaStateService: OktaAuthStateService,
    private _apiService: ApiService,
    @Inject(OKTA_AUTH) private readonly _oktaAuth: OktaAuth
  ) {
    super()
  }

  public async signOut (): Promise<void> {
    try {
      await this._oktaAuth.signOut()
    } catch (e) {
      console.error(e)
    }
  }

  subscribers(): Subscription[] {
    return [
      this._oktaStateService.authState$.subscribe((authState) => {
        this.authenticated = authState.isAuthenticated ?? false
        if (this.authenticated) {
          this._apiService.setToken(authState.accessToken?.accessToken)
          this.firstName = authState.idToken?.claims['firstName']?.toString() ?? ''
          this.lastName = authState.idToken?.claims['lastName']?.toString() ?? ''
          this.clientId = authState.idToken?.claims['ers_gtw_client_id'] as string ?? ''
          this.clientSecret = authState.idToken?.claims['ers_gtw_client_secret'] as string ?? ''
        }
      })
    ]
  }
}
