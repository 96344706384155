import { Pipe, PipeTransform } from '@angular/core'
import { DATE_FORMAT } from '../utils/constants'
import { DateTime } from 'luxon'

@Pipe({
  name: 'timezoneDate'
})
export class TimezoneDatePipe implements PipeTransform {

  transform(utcTimestamp: string | Date | undefined, targetTimezone: string | undefined): string {
    if (!utcTimestamp) {
      return ''
    }

    let dateTime
    if(utcTimestamp instanceof Date) {
      dateTime = DateTime.fromJSDate(utcTimestamp, { zone: 'utc' })
    } else {
      dateTime = DateTime.fromISO(utcTimestamp, { zone: 'utc' })
    }
    const targetDateTime = dateTime.setZone(targetTimezone || 'UTC')
    return targetDateTime.toFormat(DATE_FORMAT)
  }
}
