import { Injectable } from '@angular/core'
import { StatusType } from 'lib-automotive-call-cdm/src/models/cdm/shared/status'
import axios, { AxiosResponse } from 'axios'
import { environment } from '../../environments/environment'
import {
  ActiveCallsResponse,
  AgentCall,
  AgentComment,
  GetCallsRequest,
  RapAppLinkResponse,
  StatusHistory
} from '../model/models'

const v1Path = '/v1/agent'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  _client = axios.create({
    ...environment.axiosConfig
  })

  constructor() {
  }

  setToken = (bearerToken: string | undefined): void => {
    this._client.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`
  }

  getCalls = (getCallsRequest: GetCallsRequest): Promise<AxiosResponse<ActiveCallsResponse>> =>
    this._client.get(`${v1Path}/calls`, {
      params: getCallsRequest
    })

  getRapAppLink = (identifier: string): Promise<AxiosResponse<RapAppLinkResponse>> =>
    this._client.post(`${v1Path}/rap/applink`, {
      identifier
    })

  addComment = (callKey: string, agentComment: AgentComment): Promise<AxiosResponse<void>> =>
    this._client.post(`${v1Path}/calls/${callKey}/agentcomments`, agentComment)

  updateAgentStatus = (callKey: string, status: StatusType): Promise<AxiosResponse<void>> =>
    this._client.post(`${v1Path}/calls/${callKey}/agentstatus`, {
      status
    })

  getCallHistory = (callKey: string): Promise<AxiosResponse<StatusHistory[]>> => this._client.get(`${v1Path}/calls/${callKey}/history`)

  getCallDetail = (callKey: string): Promise<AxiosResponse<AgentCall>> => this._client.get(`${v1Path}/calls/${callKey}`)

  cancelCall = (callKey: string, appId: string): Promise<AxiosResponse<AgentCall>> => this._client.delete(`${v1Path}/calls/${callKey}`, {
    params: {
      appId
    }
  })

}
