import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CallsState } from '../reducer/calls.reducer'
import { AgentCall } from '../model/models'

const selectCallsState = createFeatureSelector<CallsState>('calls')

export const selectCalls = createSelector(
  selectCallsState,
  (state: CallsState): AgentCall[] => state.calls
)

export const selectCall = createSelector(
  selectCallsState,
  (state: CallsState): AgentCall | undefined => state.call
)
