import { Pipe, PipeTransform } from '@angular/core'
import { AgentCall } from '../model/models'
import { TimezoneDatePipe } from './timezone-date.pipe'

@Pipe({
  name: 'eta'
})
export class EtaPipe implements PipeTransform {
  transform(call: AgentCall): string {
    if (call.isActive !== 'A') {
      return 'N/A'
    }
    const timezonePipe = new TimezoneDatePipe()
    return timezonePipe.transform(call.eta || call.service?.status?.pta, call.service?.timezoneId)
  }

}
