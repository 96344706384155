<div>
  <mat-card>
    <mat-card-header>
      <mat-card-title>Create Call</mat-card-title>
      <mat-card-subtitle>Enter latitude and longitude</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <mat-form-field>
        <mat-label>Latitude</mat-label>
        <input matInput placeholder="Enter latitude" type="number" id="latitude" [(ngModel)]="latitude" (input)="updateMap()">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Longitude</mat-label>
        <input matInput placeholder="Enter longitude" type="number" id="longitude" [(ngModel)]="longitude" (input)="updateMap()">
      </mat-form-field>

      <div #mapContainer class="map-container"></div>

      <div class="button-container">
        <button mat-button color="primary" (click)="navigate()">Create Member Call</button>
        <p>Launch DRR in Member Mode</p>
      </div>
      <div class="button-container">
        <ng-container *ngIf='rapAppLinkLoading else rapAppLinkButton'>
          <div class='navigate-rap-spinner'>
            <mat-spinner diameter='20'></mat-spinner>
          </div>
        </ng-container>
        <ng-template #rapAppLinkButton>
          <button mat-button color="primary" (click)="navigateRap()">Create Non-Member Call</button>
        </ng-template>
        <p>Launch DRR in Rap Mode</p>
      </div>
    </mat-card-content>

  </mat-card>

</div>
