import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { AgentCall, AgentComment, StatusHistory } from '../model/models'
import { loadCallDetails, setCall } from '../actions/calls.actions'
import { Store } from '@ngrx/store'
import { selectCall } from '../selectors/calls.selectors'
import { ApiService } from '../services/api.service'
import { OktaAuthStateService } from '@okta/okta-angular'
import { StateManagedComponent } from '../utils/StateManagedComponent'
import { Subscription } from 'rxjs'
import { GenericDialog, GenericDialogComponent } from '../generic-dialog/generic-dialog.component'
import { ContactType } from 'lib-automotive-call-cdm/src/models/cdm/shared/contact'

enum StatusType {
  Spotted = 'SP',
  Assigned = 'AS',
  Dispatched = 'DI',
  EnRoute = 'ER',
  OnLocation = 'OL',
  InTow = 'TW',
  Cancelled = 'CA',
  Cleared = 'CL',
  Declined = 'DC',
  ClearPending = 'CP',
}

@Component({
  selector: 'app-call-details-dialog',
  templateUrl: './call-details-dialog.component.html',
  styleUrls: ['./call-details-dialog.component.scss']
})
export class CallDetailsDialogComponent extends StateManagedComponent implements AfterViewInit {

  @ViewChild('cancelCallConfirmation') cancelCallConfirmationTemplate: any;

  statusHistoryList: StatusHistory[] = []
  manualStatusEdit = false
  addingNote = false
  savingAgentData = false
  loadingStatusHistory = false
  call$ = this.store.select((selectCall))
  call: AgentCall
  statuses: StatusType[] = Object.values(StatusType)
  status: StatusType | undefined
  newNote = ''
  user = {
    firstName: '',
    lastName: ''
  }

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: AgentCall,
    private store: Store,
    private apiService: ApiService,
    private readonly _oktaStateService: OktaAuthStateService,
    private readonly dialog: MatDialog,
  ) {
    super()
    this.call = data
    this.status = this.call.agentStatus
  }

  subscribers(): Subscription[] {
    return [
      this._oktaStateService.authState$.subscribe((authState) => {
        this.user = {
          firstName: authState.idToken?.claims['firstName']?.toString() ?? '',
          lastName: authState.idToken?.claims['lastName']?.toString() ?? ''
        }
      }),
      this.call$.subscribe((call) => {
        this.call = call || this.data
      })
    ]
  }

  addNote = (): void => {
    if (this.newNote) {
      this.addingNote = true
      const agentComment: AgentComment = {
        comment: this.newNote,
        contact: {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          contactType: 'AGENT' as ContactType,
        }
      }
      this.apiService.addComment(this.call.callKey, agentComment)
        .then(() => this.store.dispatch(loadCallDetails({ callKey: this.call.callKey })))
        .catch((err) => {
          console.error(err)
          alert('Something went wrong.')
        })
        .finally(() => this.addingNote = false)
      this.newNote = ''
    }
  }

  enableManualStatusEdit = () => {
    this.manualStatusEdit = true
  }

  saveAgentStatus = () => {
    this.manualStatusEdit = false
    this.savingAgentData = true
    this.apiService.updateAgentStatus(this.call.callKey, this.status as StatusType)
      .then(() => this.store.dispatch(loadCallDetails({ callKey: this.call.callKey })))
      .catch((err) => {
        console.error(err)
        alert('Something went wrong.')
      })
      .finally(() => this.savingAgentData = false)
  }

  confirmCancelCall = () => {
    this.dialog.open(GenericDialogComponent, {
      data: {
        message: 'Are you sure you want to cancel this call?',
        title: 'Cancel Call',
        accept: () => {
          this.cancelCall()
        }
      } as GenericDialog
    })
  }

  cancelCall = () => {
    this.savingAgentData = true
    this.apiService.cancelCall(this.call.callKey, this.call.appId)
      .then((response) => {
        this.store.dispatch(setCall({ call: response.data }))
        this.dialog.open(GenericDialogComponent, {
          data: {
            title: 'The cancel request has been submitted.',
            template: this.cancelCallConfirmationTemplate,
            cancelLabel: 'OK'
          } as GenericDialog
        })
      })
      .catch((err) => {
        console.error(err)
        alert('Something went wrong.')
      })
      .finally(() => this.savingAgentData = false)
  }

  ngAfterViewInit(): void {
    this.store.dispatch(setCall({ call: this.data }))
    this.loadStatusHistory()
  }

  refreshCall(): void {
    this.store.dispatch(loadCallDetails({ callKey: this.data.callKey }))
    this.loadStatusHistory()
  }

  loadStatusHistory = () => {
    // this.loadingStatusHistory = true
    this.apiService.getCallHistory(this.call.callKey)
      .then((response) => {
        this.statusHistoryList = response.data
      })
      .catch((err) => {
          console.error(err)
          alert('Something went wrong.')
      })
      // .finally(() => this.loadingStatusHistory = false)
  }
}
