<mat-toolbar color="primary" class="toolbar">
  <mat-toolbar-row>
    <span>ERS Agent Web</span> <span class='app-version'>v{{appVersion}}</span>
    <span class="spacer"></span>
    <span class="user-name" *ngIf="authenticated">{{ firstName }} {{ lastName }}</span>
    <button mat-button routerLink="/dashboard" routerLinkActive="active">Dashboard</button>
    <button mat-button routerLink="/create-call" routerLinkActive="active">Create Call</button>
    <button mat-button (click)="signOut()">Sign out</button>
  </mat-toolbar-row>
</mat-toolbar>
<div class="container">
  <router-outlet></router-outlet>
</div>
