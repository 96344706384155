import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular'
import { CreateCallComponent } from './create-call/create-call.component'
import { DashboardComponent } from './dashboard/dashboard.component'

const routes: Routes = [
  { path: 'login/callback', component: OktaCallbackComponent },
  { path: 'create-call', component: CreateCallComponent, canActivate: [OktaAuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [OktaAuthGuard] }
  // {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
