import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { CallDetailsDialogComponent } from '../call-details-dialog/call-details-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { AgentCall } from '../model/models'
import { loadAllCalls } from '../actions/calls.actions'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { selectCalls } from '../selectors/calls.selectors'
import { MatPaginator } from '@angular/material/paginator'
import { AddressPipe } from '../pipes/address.pipe'
import { ServiceDescriptionPipe } from '../pipes/service-description.pipe'
import { TitleCasePipe } from '@angular/common'
import { StatusPipe } from '../pipes/status.pipe'
import { StateManagedComponent } from '../utils/StateManagedComponent'
import { environment } from '../../environments/environment'
import { DateTime } from 'luxon'
import { EtaPipe } from '../pipes/eta.pipe'

interface AgentCallDataTable {
  callKey: string
  createdAt: Date
  customer: string
  service: string
  pickupLocation: string
  dropOffLocation: string
  servicingClub: string
  status: string
  eta: string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends StateManagedComponent implements AfterViewInit {

  loadingCalls = false
  calls: AgentCall[] = []
  displayedColumns: string[] = [
    'callKey',
    'createdAt',
    'customer',
    'service',
    'pickupLocation',
    'dropOffLocation',
    'servicingClub',
    'status',
    'agentStatus',
    'eta'
  ]
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor (
    private readonly dialog: MatDialog,
    private store: Store,
    private addressPipe: AddressPipe,
    private serviceDescriptionPipe: ServiceDescriptionPipe,
    private titleCase: TitleCasePipe,
    private statusPipe: StatusPipe,
    private etaPipe: EtaPipe,
  ) {
    super()
  }

  subscribers(): Subscription[] {
    return [
      this.calls$.subscribe((activeCalls) => {
        this.calls = activeCalls
        this.dataSource.data = activeCalls
          .map(call => ({
            callKey: call.callKey,
            createdAt: new Date(call.createdTimestamp),
            customer: this.titleCase.transform(`${call.customer?.contact?.firstName} ${call.customer?.contact?.lastName}`),
            service: this.serviceDescriptionPipe.transform(call.service?.troubleCodes),
            pickupLocation: this.addressPipe.transform(call.serviceLocation.foi?.location?.address),
            dropOffLocation: this.addressPipe.transform(call.towDestination?.foi?.location?.address),
            servicingClub: call.channel?.org?.code,
            status: this.statusPipe.transform(call.callStatus),
            timezoneId: call.service?.timezoneId,
            eta: this.etaPipe.transform(call),
            agentStatus: this.statusPipe.transform(call.agentStatus),
          }) as AgentCallDataTable)
        // this.loadingCalls = false
      })
      // this.loadingCalls = true
    ]
  }

  ngAfterViewInit (): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.requestCalls()
  }

  requestCalls = () => this.store.dispatch(loadAllCalls({
    startDateTime: DateTime.now().minus({ hours: environment.calls.displayRange })
  }))

  openDialog (callKey: string): void {
    this.dialog.open(CallDetailsDialogComponent, {
      width: '100%',
      data: this.calls.filter(call => call.callKey === callKey)[0]
    })
  }

  calls$: Observable<AgentCall[]> = this.store.select(selectCalls)
  dataSource = new MatTableDataSource<AgentCallDataTable>([])

  applyFilter (event: Event): void {
    this.dataSource.filter = (event.target as HTMLInputElement).value.trim().toLowerCase()
  }

}
