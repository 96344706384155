<h2 mat-dialog-title>
  Call Details - {{call.callKey}}
  <button class="refresh-button" mat-button color="primary" (click)="refreshCall()">
    <mat-icon class='refresh-icon' fontIcon="refresh"></mat-icon> Refresh
  </button>
</h2>
<button mat-icon-button mat-dialog-close class='close-button'>
  <mat-icon>close</mat-icon>
</button>
<mat-dialog-content>
  <div class='row'>
    <table class='table-info'>
      <tr>
        <th> Call Key</th>
        <td> {{call.callKey}}</td>
      </tr>
      <tr>
        <th> Created At</th>
        <td> {{call.createdTimestamp | timezoneDate : call.service?.timezoneId}}</td>
      </tr>
      <tr>
        <th> Customer</th>
        <td> {{call.customer?.contact?.firstName}} {{call.customer?.contact?.lastName}}</td>
      </tr>
      <tr>
        <th> Service</th>
        <td> {{call.service?.troubleCodes | serviceDescription}}</td>
      </tr>
      <tr>
        <th> Pickup Location</th>
        <td> {{call.serviceLocation.foi?.location?.address | address}}</td>
      </tr>
      <tr>
        <th> Drop Off Location</th>
        <td> {{call.towDestination?.foi?.location?.address | address}}</td>
      </tr>
      <tr>
        <th> ETA</th>
        <td> {{ call | eta }}</td>
      </tr>
      <tr>
        <th> Servicing Club</th>
        <td> {{call.channel?.org?.code}}</td>
      </tr>
      <tr>
        <th> Tracking Link</th>
        <td><a href='{{call.serviceTracking | trackingLink}}' target='_blank'>{{call.serviceTracking | trackingLink}}</a></td>
      </tr>
      <tr>
        <th> Status</th>
        <td> {{call.callStatus | status}}</td>
      </tr>
      <tr>
        <th> Agent</th>
        <td> {{call.channel?.contact?.firstName}} {{call.channel?.contact?.lastName}}</td>
      </tr>
      <tr>
        <th> Agent Status</th>
        <td>
          <ng-container *ngIf='(!manualStatusEdit && !savingAgentData) else manualStatusEditor'>
            {{ call.agentStatus | status }}
            <i class='material-icons edit-status' href='#' (click)='enableManualStatusEdit()'>edit</i>
            <button class='cancel-button' mat-button color='warn' (click)='confirmCancelCall()'>Cancel this call</button>
          </ng-container>
          <ng-template #manualStatusEditor>
            <mat-select class='select-status' [(ngModel)]="status">
              <mat-option *ngFor="let status of statuses" [value]="status">{{ status | status }}</mat-option>
            </mat-select>
            <ng-container *ngIf='savingAgentData else saveAgentStatusButton'>
              <div class='navigate-rap-spinner'>
                <mat-spinner diameter='20'></mat-spinner>
              </div>
            </ng-container>
            <ng-template #saveAgentStatusButton>
              <button mat-button color='secondary' (click)='saveAgentStatus()'>Update</button>
            </ng-template>
          </ng-template>
          <ng-template #cancelCallConfirmation>
            <b>Agent status</b> is now Canceled.<br>
            The <b>call status</b> may take longer to update.
          </ng-template>
        </td>
      </tr>
    </table>
    <div class='right-side-panel'>
      <mat-card class='card'>
        <mat-card-header>
          <mat-card-title>History</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <ng-container *ngIf='loadingStatusHistory else listStatusHistory'>
            <div style='width: 100%; padding-left: 45%;'>
              <mat-spinner diameter='30'></mat-spinner>
              <div style='margin-top: 20px; margin-left: -38px;'>Loading history...</div>
            </div>
          </ng-container>

          <ng-template #listStatusHistory>
            <table mat-table [dataSource]='statusHistoryList'>
              <ng-container matColumnDef='timestamp'>
                <th mat-header-cell *matHeaderCellDef> Timestamp</th>
                <td mat-cell *matCellDef='let data'> {{ data.statusTimestamp | timezoneDate:call.service?.timezoneId }} </td>
              </ng-container>

              <ng-container matColumnDef='status'>
                <th mat-header-cell *matHeaderCellDef> Status</th>
                <td mat-cell *matCellDef='let data'> {{ data.status | status }} </td>
              </ng-container>

              <ng-container matColumnDef='eta'>
                <th mat-header-cell *matHeaderCellDef> ETA</th>
                <td mat-cell *matCellDef='let data'> {{ data.eta | timezoneDate:call.service?.timezoneId }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['timestamp', 'status', 'eta']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['timestamp', 'status', 'eta']"></tr>
            </table>
          </ng-template>
        </mat-card-content>
      </mat-card>
      <mat-card class='card'>
        <mat-card-header>
          <mat-card-title>Notes</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-card *ngFor='let note of call.agentComments' style='margin-bottom: 20px;'>
            <mat-card-content>
              <div>{{ note.text }}</div>
              <div style='margin-top: 10px;'><b>Created by</b> {{ note.contact?.firstName }} {{ note.contact?.lastName }} <b>at</b> {{ note.commentDate | timezoneDate:call.service?.timezoneId }}</div>
            </mat-card-content>
          </mat-card>
          <mat-form-field>
            <input matInput placeholder='Enter a note' [(ngModel)]='newNote' />
          </mat-form-field>
          <br />
          <ng-container *ngIf='addingNote else addNoteButton'>
            <div class='navigate-rap-spinner'>
              <mat-spinner diameter='20'></mat-spinner>
            </div>
          </ng-container>
          <ng-template #addNoteButton>
            <button mat-raised-button color='secondary' (click)='addNote()'>Add Note</button>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <!-- Dialog actions -->
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
